import React, { useState } from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Divider, Grid, Tabs, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import StyledTab from '../../../custom/StyledTab';
import PrimaryFranchise from './PrimaryFranchise';
import SecondaryFranchise from './SecondaryFranchise';
import SquareLoyaltyList from './SquareLoyaltyList';
import ImportSquareLoyalties from './ImportSquareLoyalties';

const MapSquareGiftCard = () => {
  const [formTab, setTab] = useState(0);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Square Map Merchants IDS - Sync Loyalty Cards
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
          aria-label="Select settings tab"
        >
          <StyledTab
            disableRipple
            id="primary"
            aria-controls="values"
            label="Primary Merchants"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Secondary Merchants"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Get Initial List of Loyalty Customers"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Import Initial Loyalty Customers"
          />
        </Tabs>
      </Box>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0}>
            {formTab === 0 ? <PrimaryFranchise type={1} /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            {formTab === 1 ? <SecondaryFranchise type={1} /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            {formTab === 2 ? <SquareLoyaltyList /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            {formTab === 3 ? <ImportSquareLoyalties /> : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </div>
  );
};
export default MapSquareGiftCard;
