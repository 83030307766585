import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import MapCloverMerchants from './MapCloverMerchants';
import MapSquareMembership from './MapSquareMembership';
import MapSquareGiftCard from './Franchise/MapSquareGiftCard';
import MapSquareLoyalty from './Franchise/MapSquareLoyalty';

const GiftCardConfigs = () => {
  return (
    <Router basepath={withPrefix('dashboard/map-merchants')}>
      <MapCloverMerchants path="/" />
      <MapCloverMerchants path="/clover" />
      <MapSquareMembership path="/square/membership" />
      <MapSquareGiftCard path="/square/giftCard" />
      <MapSquareLoyalty path="/square/loyalty" />
    </Router>
  );
};

export default GiftCardConfigs;
