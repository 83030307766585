/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import moment from 'moment/moment';
import { TransactionTable } from '../../../custom';
import AddSecondaryFranchiseModal from '../Modal/AddSecondaryFranchiseModal';
import franchises from '../../../../state/franchises';
import SyncFranchiseGiftCardModal from '../Modal/SyncFranchiseGiftCardModal';
import { alert, memberships } from '../../../../state';

const colsTitles = [
  'Group / Franchise Name',
  'Secondary Merchant Name',
  'Secondary Merchant Id',
  'POS',
  'Pintuna - Secondary Merchant Id',
  'Primary Merchant Name',
  'Square - Primary Merchant Id',
  'Pintuna - Primary Merchant Id',
  'Enabled',
  'Added On',
];

const rowKeys = [
  'franchise.name',
  'business.name',
  'business.merchantId',
  'business.partner',
  'business.id',
  'franchise.business.name',
  'franchise.business.merchantId',
  'franchise.business.id',
  'enabled',
  'createdAt',
];

// eslint-disable-next-line react/prop-types
const SecondaryFranchise = ({ type = 0 }) => {
  const [params, setParams] = React.useState({ limit: 10, page: 1, type });
  const [isOpen, setIsOpen] = React.useState(false);
  const [openSync, setOpenSync] = React.useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));

  const getSecondaryMerchants = (p) =>
    dispatch(franchises.actions.getSecondaryMerchants(p || params));

  useEffect(() => {
    dispatch(
      franchises.actions.getFranchisesList({
        limit: 10,
        page: 1,
        type,
      })
    );
    getSecondaryMerchants(params);
  }, [params]);

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const secondaryFranchises = useSelector(
    franchises.selectors.selectSecondaryMerchant
  );

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const updateEnabled = async (row) => {
    const res = await dispatch(
      franchises.actions.createSecondaryMerchant({
        businessId: row.business.id,
        franchiseId: row.franchise.id,
        primaryBusinessId: row.franchise?.business?.id,
        name: row.name,
        id: row.id,
        enabled: !row.enabled,
        type: row.franchise?.type,
      })
    );
    if (!res.error && res.payload) {
      openAlert({
        message: 'Secondary merchant is updated successfully',
        severity: 'success',
      });
    } else {
      openAlert({
        message: res.payload || "Can't update secondary merchant",
        severity: 'error',
      });
    }
    getSecondaryMerchants(params);
  };
  const mods = {
    enabled: (val, row) =>
      val ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateEnabled(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          disabled={!row.franchise?.enabled}
          onClick={() => updateEnabled(row)}
        />
      ),
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
  };

  const closeSecondaryFranchiseModal = () => {
    setIsOpen(false);
    // setOpenSync(sync);
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getSecondaryMerchants(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
    getSecondaryMerchants(p);
  };
  const pagination = {
    total: secondaryFranchises.total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <AddSecondaryFranchiseModal
        isOpen={isOpen}
        closeModal={(sync) => closeSecondaryFranchiseModal(sync)}
        type={type}
      />

      <SyncFranchiseGiftCardModal
        isOpen={openSync}
        closeModal={() => setOpenSync(false)}
        type={type}
      />
      <Grid xs="12" lg="10" xl="8">
        <Box py={2}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={secondaryFranchises?.data || []}
            mods={mods}
            isLoading={isLoading}
            searchBox={() => (
              <Grid container spacing={2} md={12} alignItems="center">
                <Grid item>
                  <Button
                    disabled={isLoading}
                    onClick={() => handleOpenModal(true)}
                    variant="contained"
                    color="primary"
                  >
                    Add Secondary Merchant
                  </Button>
                </Grid>
                {type === 1 && (
                  <Grid item alignItem="center">
                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                      Make sure the Loyalty App is enabled for the secondary
                      merchants as well
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            pagination={pagination}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
    </>
  );
};

export default SecondaryFranchise;
