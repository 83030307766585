import React, { useState } from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Divider, Grid, Tabs, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import StyledTab from '../../../custom/StyledTab';
import PrimaryFranchise from './PrimaryFranchise';
import SecondaryFranchise from './SecondaryFranchise';
import SquareGiftCardsList from './SquareGiftCardsList';
import ImportSquareGiftCards from './ImportSquareGiftCards';
import ImportPintunaGiftCards from './ImportPintunaGiftCards';

const MapSquareGiftCard = () => {
  const [formTab, setTab] = useState(0);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Square Map Merchants IDS - Sync Gift Card (Franchise)
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
          aria-label="Select settings tab"
        >
          <StyledTab
            disableRipple
            id="primary"
            aria-controls="values"
            label="Primary Merchants"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Secondary Merchants"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Get Initial List of Gift Cards"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Import Initial Gift Cards"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Import Gift Cards to Pintuna DB"
          />
        </Tabs>
      </Box>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0} style={{ padding: 0 }}>
            {formTab === 0 ? <PrimaryFranchise /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 1 ? <SecondaryFranchise /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 2 ? <SquareGiftCardsList /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 3 ? <ImportSquareGiftCards /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 4 ? <ImportPintunaGiftCards /> : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </div>
  );
};
export default MapSquareGiftCard;
